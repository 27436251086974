
import { Component, Vue, } from 'vue-property-decorator'
import InputComponent from '@/components/InputHackmetrix/Index.vue'
import SelectComponent from '@/components/SelectHackmetrix/Index.vue'

@Component({
  components: {
    InputComponent,
    SelectComponent,
  },
})
export default class CheatseetComponent extends Vue {}
